import { FC } from "react"
import moment from "moment"
import { getApiUrl } from "../../../utils/getApiUrl"
import AttachmentItemChat from "./assets/AttachmentItemChat"
import { urlify } from "../../../utils/urlify"
import { IChatMessage } from "../../../types/content"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./ChatModal.module.scss"

interface Props {
  message: IChatMessage
  nowUserId: string
  openPhoto?: any // ф-я открытия фото
}

const ChatMessage: FC<Props> = ({ message, nowUserId, openPhoto = () => null }) => {
  const itsMe = message?.user?.id === nowUserId

  return (
    <div className={`${styles.chatMessage} chatMessage ${itsMe ? styles.chatMessage_my : ""} `}>
      {!itsMe && (
        <GetUserAvatar
          name={message?.user?.name}
          avatar={message?.user?.avatar_id}
          size={"xsm"}
          className={styles.chatMessage__avatar}
        />
      )}
      <div className={`${styles.chatMessage__block}`}>
        {!itsMe && message?.user?.name && <div className={`${styles.chatMessage__name}`}>{message.user.name}</div>}
        <div className={`${styles.chatMessage__text}`} dangerouslySetInnerHTML={{ __html: urlify(message.text) }} />
        {/* Изображения */}
        {!!message.photos?.length && (
          <div className={`${styles.chatMessage__images}`}>
            {message.photos?.map((photo: string, index: number) => {
              const info = message.photos_info.find((i) => i.id === photo)
              const width = !info?.width || !info?.height ? undefined : info.height > info.width ? "auto" : info.width
              const height = !info?.width || !info?.height ? undefined : info.width > info.height ? "auto" : info.height
              return (
                <img
                  key={index}
                  onClick={() => openPhoto(message.photos_info, index)}
                  src={getApiUrl() + "/public/photo/" + photo}
                  alt=""
                  width={width}
                  height={height}
                />
              )
            })}
          </div>
        )}
        {/* Файлы */}
        {/* {!!message.photos?.length &&  */}
        <div className={`${styles.chatMessage__files}`}>
          {message.attachments_info?.map((el: any, kk: number) => {
            return <AttachmentItemChat el={el} key={kk} />
          })}
        </div>
        {/* } */}
        <div className={`${styles.chatMessage__time}`}>{moment(message.created_at).format("HH:mm")}</div>
      </div>
    </div>
  )
}

export default ChatMessage
