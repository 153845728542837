import { FC, MouseEvent, ReactNode, useMemo } from "react"
import clsx from "clsx"
import ButtonWrap from "./ButtonWrap"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import styles from "./Button.module.scss"

type TIconName = "arrow"

interface Props {
  className?: string
  icon?: JSX.Element | JSX.Element[] | ReactNode | TIconName
  txt: string | ReactNode
  txtMobile?: string
  mode?: "gray" | "warning" | "white" | "light"
  size?: "sm" | "sm-width" | "md"
  href?: string
  isTargetBlank?: boolean
  type?: "button" | "submit" | "reset"
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) => void
  disabled?: boolean
  isLoading?: boolean
  props?: any
}

const Button: FC<Props> = ({
  className = "",
  icon,
  txt,
  txtMobile,
  mode,
  size,
  href,
  isTargetBlank,
  type = "button",
  onClick,
  disabled,
  isLoading,
  ...props
}) => {
  const iconEl = useMemo(() => {
    if (!icon) return null
    switch (icon) {
      case "arrow":
        return <ArrowRightIcon />
      // return <IconArrow />
      default:
        return icon
    }
  }, [icon])

  return (
    <ButtonWrap
      type={type}
      href={href}
      onClick={onClick}
      isTargetBlank={isTargetBlank}
      disabled={disabled}
      className={clsx(
        styles.btn,
        mode && styles["btn--" + mode],
        size && styles["btn--" + size],
        isLoading && styles["btn--is-loading"],
        className,
      )}
      {...props}
    >
      {txtMobile ? (
        <span>
          <span>{txt}</span>
          <span>{txtMobile}</span>
        </span>
      ) : (
        txt
      )}
      {isLoading && <img className={styles.btn__loader} src={"/img/loader.png"} alt="Loading..." />}
      {icon && <span className={styles.btn__icon}>{iconEl}</span>}
    </ButtonWrap>
  )
}

export default Button
