import clsx from "clsx"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IReview } from "../../types/content"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./Review.module.scss"

interface Props extends IReview {
  className?: string
}

const Review = ({ order_id, order_name, text, created_at, assigneeUsers, rating, className }: Props) => {
  return (
    <div className={clsx(styles.item, className)}>
      <h4 className={styles.title}>{order_name}</h4>
      {text && <p className={styles.txt}>{text}</p>}
      <div className={styles.assignees}>
        {assigneeUsers?.map((assignee) => {
          return (
            <div key={assignee.id} className={styles.assignee}>
              <GetUserAvatar avatar={assignee?.avatar_id} name={assignee?.name} className={styles.avatar} size={"sm"} />
              <div className={styles.content}>
                <p className={styles.name}>{assignee.name}</p>
                <StarsRating
                  rating={rating}
                  size={"sm"}
                  name={"review-rate-" + order_id + "-" + assignee.id}
                  disabled
                />
              </div>
            </div>
          )
        })}
        <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>
      </div>
    </div>
  )
}

export default Review
