import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TextareaAutosize from "react-textarea-autosize"
import { useLocation } from "react-router-dom"
import { usePostOrderModalWasOpenedMutation, useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import HistoryOrdModalSkeleton from "../HistoryOrdModal/HistoryOrdModalSkeleton"
import StarsRating from "../../Assets/StarsRating/StarsRating"
import { useDispatch } from "react-redux"
import { changeOrderHasRevied } from "../../../redux/slice/ordersList"
import { countOrdersWithoutReview, updateCountOrdersWithoutReview } from "../../../redux/slice/main"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import { useAppSelector } from "../../../hooks"
import useWindowSize from "../../../hooks/useWindowSize"
import { useLazySendReviewQuery } from "../../../redux/api/review"
import { IOrderWithoutReview, IReview } from "../../../types/content"
import { GetUserAvatar } from "../../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./RatingModal.module.scss"
import { IAssignee } from "../../../types/orderTypes"

interface IRatingModal {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  orderId: string
  setReviews?: Dispatch<
    SetStateAction<{
      orders: IOrderWithoutReview[]
      reviews: IReview[]
    }>
  >
  onSubmit?: (id: string) => void
}

const RatingModal: FC<IRatingModal> = ({ orderId, open, setOpen, setReviews, onSubmit }) => {
  const [step, setStep] = useState<number>(1)
  const [comment, setComment] = useState<string>("")
  const [rating, setRating] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [getServiceOrder] = useLazyGetServiceOrderQuery()
  const [orderInfo, setOrderInfo] = useState<any>(null)
  const [newReview, setNewReview] = useState<IReview | null>(null)
  const { isDesktop, isMobile } = useWindowSize()

  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)
  const [postOrderModalWasOpened] = usePostOrderModalWasOpenedMutation()

  const [sendReview] = useLazySendReviewQuery()

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { allowScroll } = useScrollBlock()

  useEffect(() => {
    location.pathname.includes("history") ? setStep(1) : setStep(2)
    setIsLoading(true)
    getServiceOrder(orderId)
      .then((result) => {
        setOrderInfo(result?.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
    // отправка события об открытии модалки
    postOrderModalWasOpened(orderId)
  }, [])

  const doClose = () => {
    setOpen(false)
    allowScroll(true)
    setRating(0)

    if (onSubmit && newReview) onSubmit(orderId)

    if (!setReviews || !newReview) return
    setReviews((prev) => {
      return {
        orders: [...prev.orders.filter((order) => order.id !== orderId)],
        reviews: [newReview, ...prev.reviews],
      }
    })
    setNewReview(null)
  }

  const changeRatingReviewHandler = (value: any) => {
    setRating(value)
  }

  const submitForm = () => {
    const data = { id: orderId, txt: comment, rating: rating }
    setIsLoading(true)
    sendReview(data)
      .then((res) => {
        if (!res?.data) return
        setNewReview(res.data)
        setStep(3)
        dispatch(changeOrderHasRevied({ id: orderId, hasReview: true }))
        setComment("")
        if (quantityOrdersWithoutReview) {
          dispatch(updateCountOrdersWithoutReview(quantityOrdersWithoutReview - 1))
        }
      })
      .catch((er) => console.error(er.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={doClose}
      isCloseBtn={isMobile}
      className={clsx(
        styles.modal,
        styles["modal--step-" + step],
        isLoading && styles["modal--is-loading"],
        !isDesktop && styles["rating-modal-mobile"],
      )}
      header={
        isLoading ? undefined : step === 1 ? (
          <h2 className={clsx("modal__title", styles.title)}>{orderInfo?.name}</h2>
        ) : step === 2 ? (
          <>
            <GetUserAvatar isOrder avatar={orderInfo?.photos?.length > 0 ? orderInfo.photos[0] : undefined} />
            <div className={styles.titleWrap}>
              <p>{t("serviceCompleted")}</p>
              <h2 className={clsx("modal__title", styles.title)}>
                {orderInfo?.name} {orderInfo?.count > 1 && <span> x{orderInfo?.count}</span>}
              </h2>
            </div>
          </>
        ) : undefined
      }
    >
      {isLoading ? (
        <HistoryOrdModalSkeleton />
      ) : (
        <div className={styles.RatingModal}>
          {step === 1 || step === 2 ? (
            <>
              <div className={styles.RatingModal__executorTitle}>{t("executor")}</div>
              <div className={styles.assignees}>
                {orderInfo?.assignees?.map((assignee: IAssignee) => {
                  return (
                    <div key={assignee.id} className={styles.RatingModal__block}>
                      <p className={styles.RatingModal__executorName}>{assignee.name}</p>
                      <GetUserAvatar
                        avatar={assignee?.avatar_id}
                        name={assignee?.name}
                        className={styles.avatar}
                        size={isMobile ? "xmd" : undefined}
                      />
                    </div>
                  )
                })}
              </div>
              {!orderInfo?.assignees?.length && t("executorNotFound")}

              <div className={styles.RatingModal__ratingBlock}>
                <div className={styles.RatingModal__ratingTitle}>{t("rating")}</div>
                <StarsRating
                  name="rating"
                  onChange={changeRatingReviewHandler}
                  className={clsx(step === 1 && "rating-modal__stars")}
                  size={isMobile && step === 2 ? "lg" : undefined}
                />
              </div>

              <div className={`modal-input ${styles.RatingModal__input}`}>
                <div className={`input-label ${styles.RatingModal__ratingTitle}`}>{t("review")}</div>
                <TextareaAutosize
                  minRows={1}
                  maxRows={5}
                  placeholder={t("yourReview")}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>

              <div className={`${styles.RatingModal__bottom}`}>
                <Button
                  txt={t("back")}
                  onClick={doClose}
                  mode={"gray"}
                  className={clsx(step === 2 && styles.backBtn)}
                />
                <Button
                  txt={t("evaluate")}
                  disabled={!rating}
                  onClick={submitForm}
                  className={clsx(step === 2 && styles.submitBtn)}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.RatingModal__title}>{t("thanksReviewTitle")}</div>
              <div className={styles.RatingModal__descrBlock}>
                <div className={styles.RatingModal__descr}>{t("thanksReviewCommonDescr")}</div>
              </div>
              <Button txt={t("itIsClear")} className={styles.btnIts} onClick={doClose} size={"md"} />
            </>
          )}
        </div>
      )}
    </ModalPortal>
  )
}

export default RatingModal
