// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Review_item__GDbG6 {
  background-color: var(--white);
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  padding: 8px 12px 12px;
  border-radius: 12px;
}

.Review_title__pSAso {
  font-weight: 500;
  margin-bottom: 6px;
}

.Review_txt__EMCpz {
  color: #3D4149;
  margin-bottom: 6px;
}

.Review_assignees__1MSEz {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 8px;
  padding-top: 4px;
}

.Review_assignee__fKifr {
  display: flex;
}

.Review_avatar__gA0o0 {
  margin: 0 8px 0 4px;
}

.Review_name__wLR1D {
  color: #54575C;
  word-break: break-word;
  margin-bottom: 3px;
}

.Review_time__rpX9E {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  color: #7A808B;
  flex-shrink: 0;
  align-self: flex-end;
  margin-left: auto;
  transform: translateY(4px);
}`, "",{"version":3,"sources":["webpack://./src/components/Review/Review.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,uDAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;EACA,cAAA;EACA,oBAAA;EACA,iBAAA;EACA,0BAAA;AACF","sourcesContent":[".item {\r\n  background-color: var(--white);\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  padding: 8px 12px 12px;\r\n  border-radius: 12px;\r\n}\r\n\r\n.title {\r\n  font-weight: 500;\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.txt {\r\n  color: #3D4149;\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.assignees {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n  grid-gap: 8px;\r\n  padding-top: 4px;\r\n}\r\n\r\n.assignee {\r\n  display: flex;\r\n}\r\n\r\n.avatar {\r\n  margin: 0 8px 0 4px;\r\n}\r\n\r\n.name {\r\n  color: #54575C;\r\n  word-break: break-word;\r\n  margin-bottom: 3px;\r\n}\r\n\r\n.time {\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n  color: #7A808B;\r\n  flex-shrink: 0;\r\n  align-self: flex-end;\r\n  margin-left: auto;\r\n  transform: translateY(4px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Review_item__GDbG6`,
	"title": `Review_title__pSAso`,
	"txt": `Review_txt__EMCpz`,
	"assignees": `Review_assignees__1MSEz`,
	"assignee": `Review_assignee__fKifr`,
	"avatar": `Review_avatar__gA0o0`,
	"name": `Review_name__wLR1D`,
	"time": `Review_time__rpX9E`
};
export default ___CSS_LOADER_EXPORT___;
